import BaseBean from "@/utils/BaseBean";

export interface IHdgsCardDataObj {
    utilInst:HdgsCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class HdgsCardUtil extends BaseBean{
    public dataObj:IHdgsCardDataObj;

    constructor(proxy:any,dataObj:IHdgsCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();
    }
}